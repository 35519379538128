import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { DiscussionEmbed } from "disqus-react"

const Blog = ({ data, location }) => {
  const { html, frontmatter } = data.markdownRemark
  const { title } = frontmatter
  const disqusConfig = {
    shortname: 'nickmccullum',
    config: { identifier: title },
  }

  return (
    <Layout>
      <SEO title="Home" />
      <article className="page">
        <h1>{title}</h1>

        <div className="sales-text">
          <p>Hey - Nick here! This page is a free excerpt from my $99 course <a href="https://courses.nickmccullum.com/courses/">SQL Fundamentals.</a></p>
          <p>If you want the full course, <a href="https://courses.nickmccullum.com/courses/">click here to sign up and create an account.</a></p>
          <p>I have a 30-day satisfaction guarantee, so there's no risk (and a ton of upside!) in signing up for this course and leveling up your SQL skills today!</p>
        </div>
        <div className="entry" dangerouslySetInnerHTML={{ __html: html }}>
        </div>
        <DiscussionEmbed {...disqusConfig} />
      </article>
    </Layout>
  )
}
export default Blog

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
      }
      fields {
        slug
      }
    }
  }
`